<template>
	<div class="finance"  v-if="dict && dict.banner && dict.banner.serviceCentre.length > 0" >
		<search-header :currentActive="6"></search-header>
		<div class="container p-0 mt-3">
			<a :href="m.url != '' ? m.url : 'javascript:;'"  v-for="m in dict.banner.serviceCentre" :key="m.id" :title="m.title" :target="m.url != '' ? '_blank' : '_self'">
			 	<b-img class="w-100" fluid :src="m.bannerPath" ></b-img>
			</a>
		</div>
		<div class="container p-0 mb-4">
			<div class="row m-0">
				<div class="col-12 p-0 mt-3">
					<div class="border rounded p-3">
						<div class="row m-0">
							 <a v-for="(m, i) in area.province"
                                        :key="i"
                                        @click="para.province = m.id"
                                        href="javascript:;"
                                        class="mr-3 px-2 py-1"
                                        :class="{ 'text-theme': para.province == m.id }"
                                >{{ m.name }}</a>
						</div>
					</div>
				</div>

				<div class="col-12 mt-2 border rounded">
                    <div class="row pl-3 pt-2 pb-2">
						<a href="javascript:;" @click="onSortClick(i)" v-for="s,i in sort" :key="i"  class="mr-4 px-2 py-1 rounded" :class="{'text-theme' : s.active}">{{s.text}} <b-icon :icon="s.orderBy == 'desc' ? 'arrow-down-short' : 'arrow-up-short'"></b-icon></a>
                    </div>
                </div>

				<div class="col-9 pl-0 mt-2 rounded">
					<div class="border p-3 mb-3" v-for="m,i in list" :key="i">
						<div class="row">
							<div class="col-9 border-right">
								<div class="clearfix">
									<div class="float-left w-35" style="height:135px;overflow: hidden;">
										<img :src="require('@/static/img/s_'+i+'.png')" class="width-200"/>
									</div>
									<div class="float-left pl-2 w-65">
										<router-link to="/servicecentre">{{m.companyName}}</router-link>
										<div class="f-14 mt-1">
											<span class="text-secondary">经验值</span>
											<span class="text-success ml-2">{{m.experience}}</span>
											<span class="text-secondary ml-5">好评率</span>
											<span class="text-success m-2">{{m.praise}}</span>
										</div>
										<div class="f-14 mt-1">
											<span class="text-secondary">服务区域</span>
											<span class="ml-2">{{m.area}}</span>
										</div>
										<div class="f-14 mt-1">
											<span class="text-secondary">企业地址</span>
											<span class="ml-2">{{m.address}}</span>
										</div>
										<div class="mt-1">
											<b-button size="sm" variant="success">帮我买地</b-button>
											<b-button size="sm ml-3" variant="outline-success">帮我找地</b-button>
											<b-button size="sm ml-3" variant="warning">业务咨询</b-button>
										</div>
									</div>
								</div>
							</div>
							<div class="col-3 f-14">
								<div class="clearfix">
									<span class="float-left text-secondary">联系人</span>
									<span class="float-right">{{m.contacts}}</span>
								</div>
								<div class="clearfix mt-2">
									<span class="float-left text-secondary">联系电话</span>
									<span class="float-right">{{m.contact_number}}</span>
								</div>
								<div class="clearfix border-top-dashed pt-3 mt-3">
									<!-- <span class="float-left text-secondary">火热招募区县代理</span>
									<span class="float-right text-success">6个</span> -->
								</div>
								<b-button size="sm mt-3" variant="outline-success w-100">申请代理</b-button>
							</div>
						</div>
						
					</div>

					<!-- <div class="row">
                        <div class="col-12 d-flex justify-content-center">
							<b-pagination :total-rows="10" :per-page="1"
							aria-controls="my-table"></b-pagination>
                        </div>
                    </div> -->
				</div>
				<div class="col-3 mt-2 pr-0 rounded">
					<div class="border p-2 pl-3 pl-3">
						<h5 class="mb-2 ">为什么选择服务中心？</h5>
						<p class="text-success font-16">1、交易安全，专业靠谱</p>
						<p class="text-gray-6 font-14" style="text-indent:25px;">服务中心的工作人员都是经验丰富的土地相关从业者，可为客户提供专业实际的参考建议。<br>每家服务中心均交有保证金在土流网，如服务态度差或存在欺骗行为，客户可以投诉到土流网，经核实后平台可以先行赔付，交易更安全有保障。</p>
						<p class="text-success font-16">2、客源地源充足</p>
						<p class="text-gray-6 font-14 m-0" style="text-indent:25px;">土地流转具有极强的地域性，服务中心扎根在当地，对本地的政策补贴，地方行情非常清楚，且有丰厚的当地资源支持。</p>
						<p class="text-gray-6 font-14" style="text-indent:25px;">服务中心客源地源充足，且有土流网大量流量支撑，可以快速帮助客户流转土地，找到合适地源。</p>
						<p class="text-success font-16">3、一体化服务  省时省心</p>
						<p class="text-gray-6 font-14" style="text-indent:25px;">服务中心熟悉土地交易过程中的各项业务，可为客户提供一体化的土地流转服务，减少客户东奔西跑的时间，方便快捷，省时省心。</p>
					</div>
					
				</div>
			</div>
		</div>
		<map-footer></map-footer>
	</div>
</template>

<script>
   import {mapGetters} from "vuex";
   export default {
	   	 data() {
            return {
                para: {
                    province: ''
                },
				sort: [{
					text: '按经验值',
					filed: 'createdAt',
					orderBy: 'desc',
					active: true,
				},{
					text: '按成交时间',
					filed: 'measureArea',
					orderBy: 'desc',
					active: false,
				},{
					text: '按成交数',
					filed: 'price',
					orderBy: 'desc',
					active: false,
				}],
				list: [{
					companyName:'抚州市临川区土地流转服务中心',
					praise: '98%',
					experience:'3001',
					area: '江西省抚州市',
					address: '江西省抚州市临川区上顿渡镇东方威尼斯302室',
					contacts: '吴先生',
					contact_number: '15279450266'
				},{
					companyName:'抚州市东乡区土地流转服务中心',
					praise: '98%',
					experience:'2992',
					area: '抚州市东乡区',
					address: '江西省抚州市东乡区东红大道1603号',
					contacts: '张先生',
					contact_number: '13479441556'
				},{
					companyName:'抚州市乐安县土地流转服务中心',
					praise: '95%',
					experience:'1563',
					area: '抚州市乐安县',
					address: '江西省抚州市乐安县新二中红绿灯旁杨柳山庄路口',
					contacts: '李先生',
					contact_number: '18779530126'
				},{
					companyName:'抚州市南城县土地流转服务中心',
					praise: '92%',
					experience:'1136',
					area: '抚州市南城县',
					address: '江西省抚州市南城县登高东路67号',
					contacts: '李先生',
					contact_number: '15279460321'
				},{
					companyName:'抚州市黎川县土地流转服务中心',
					praise: '93%',
					experience:'963',
					area: '抚州市黎川县',
					address: '江西省抚州市黎川县京川大道203',
					contacts: '陈先生',
					contact_number: '15279466598'
				},{
					companyName:'抚州市资溪县土地流转服务中心',
					praise: '92%',
					experience:'1006',
					area: '抚州市资溪县',
					address: '江西省抚州市资溪县建设中路36号',
					contacts: '王先生',
					contact_number: '15279492654'
				}]
			}
		},
		computed: {
			...mapGetters(["area","dict"]),
		},
		methods: {
			onSortClick(i) {
				let o = this.sort.findIndex(x=> x.active == true);
				this.sort = this.sort.map(x=> { return {
					text: x.text,
					filed: x.filed,
					orderBy: x.orderBy,
					active: false
				}});
				if(o == i) {
					this.sort[i].active = true;
					this.sort[i].orderBy = this.sort[i].orderBy == 'desc' ? 'asc' : 'desc';
				} else {
					this.sort[i].active = true;
				}
				this.para.param.sort = {};
				this.para.param.sort[this.sort[i].filed] = this.sort[i].orderBy;
			},
		}
   }
</script>

<style scoped>
	.finance .box {
		border: #cccccc solid 1px;
		padding: 10px 0px 10px 15px;
		border-radius: 10px;
		width: 370px;
		height: 100px;
	}

	.finance .box .text-red {
		color: #ff6329;
	}

	.finance .ml-15 {
		margin-left: 15px;
	}
	
	.partner-box {
		border: #cccccc solid 1px;
		border-radius: 10px;
		overflow: hidden;
		width: 216px;
		height: 70px;
	}
</style>
